import { Intent } from "@blueprintjs/core"
import { useState } from "react"

import AddNewTickerDialog from "../components/AddNewTickerDialog"
import {
  AddTickerButton,
  DetailCard,
  MainContainer,
  SearchContainer,
} from "../components/CommonStyled.styled"
import Listings from "../components/Listings"
import TickerSuggest from "../components/TickerSuggest"
import useTradingEtfs from "../hooks/useTradingEtfs"
import ETFForm from "./EtfForm"
import { TradingEtf } from "./types"

const Etf = () => {
  const [selectedEtf, setSelectedEtf] = useState<TradingEtf | undefined>(undefined)
  const [isOpenDialog, setIsOpenDialog] = useState(false)

  const useEtfsWrapper = ({ query }: { query: string }) => {
    const { etfs, isLoading, error } = useTradingEtfs({ query })
    return { items: etfs ?? [], isLoading, error }
  }
  return (
    <MainContainer flexDirection="column" alignItems="center" justifyContent="center" gap="30px">
      <SearchContainer flexDirection="column" gap="20px">
        <AddTickerButton onClick={() => setIsOpenDialog(true)} icon="plus" intent={Intent.PRIMARY}>
          Add new ticker(s)
        </AddTickerButton>
        <AddNewTickerDialog
          isOpenDialog={isOpenDialog}
          setIsOpenDialog={setIsOpenDialog}
          title="Add new ticker(s) related to ETF"
        />
        <TickerSuggest<TradingEtf>
          setSelectedItem={setSelectedEtf}
          useItems={useEtfsWrapper}
          errorMessage="Unable to search ETFs, please try to refresh the page."
        />
      </SearchContainer>

      {selectedEtf && (
        <DetailCard>
          <ETFForm selectedEtf={selectedEtf} setSelectedEtf={setSelectedEtf} />
          <Listings listings={selectedEtf.listings} />
        </DetailCard>
      )}
    </MainContainer>
  )
}

export default Etf
