import { MenuItem } from "@blueprintjs/core"
import { ItemRenderer, Suggest } from "@blueprintjs/select"
import useCompanies from "../hooks/useCompanies"
import { Company } from "../../api/world"

interface CompanySuggestProps {
  selectedCompany: Company | null
  onCompanySelect: (company: Company) => void
  placeholder: string
}

/**
 * Reusable Suggest component to select a company from
 * the companies list.
 */
const CompanySuggest = ({ selectedCompany, onCompanySelect, placeholder }: CompanySuggestProps) => {
  const { companies } = useCompanies()

  const renderCompany: ItemRenderer<Company> = (company, { handleClick, modifiers }) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={company.slug}
        onClick={handleClick}
        text={company.name}
      />
    )
  }

  const searchCompany = (query: string, companies: Company[]) => {
    return companies.filter(
      company =>
        company.name.toLowerCase().includes(query.toLowerCase()) ||
        company.bbg_ticker.toLowerCase().includes(query.toLowerCase()),
    )
  }

  const noResults = (
    <MenuItem active={false} disabled={true} icon="zoom-out" text="No Companies found" />
  )

  return (
    <>
      {companies && (
        <Suggest<Company>
          items={companies}
          inputValueRenderer={(company: Company) => company.name}
          itemRenderer={renderCompany}
          itemListPredicate={searchCompany}
          onItemSelect={onCompanySelect}
          noResults={noResults}
          fill={true}
          inputProps={{ placeholder }}
          selectedItem={selectedCompany}
          resetOnSelect={true}
        />
      )}
    </>
  )
}

export default CompanySuggest
