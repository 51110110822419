import { Button, Checkbox, Popover } from "@blueprintjs/core"
import { Dispatch, Fragment, SetStateAction } from "react"
import { FlexContainer } from "../../../../styled/flexbox.styled"
import { FieldType } from "../types/business"
import { MetricSelectionContainer, MetricSelectionPopover } from "./MetricSelection.styled"

interface IMetricSelectionProps {
  seenMetrics: FieldType[]
  setSeenMetrics: Dispatch<SetStateAction<Array<FieldType>>>
}

const PopoverContent = ({ seenMetrics, setSeenMetrics }: IMetricSelectionProps) => {
  const handleChange = (value: FieldType) => {
    // Case 1 : The user checks the box
    if (!seenMetrics.includes(value)) {
      setSeenMetrics([value, ...seenMetrics])
    }
    //Case 2  : The user unchecks the box
    else {
      setSeenMetrics(seenMetrics.filter(e => e !== value))
    }
  }
  return (
    <>
      <MetricSelectionPopover
        flexDirection="row"
        gap="10px"
        alignContent="center"
        justifyContent="space-around"
      >
        {Object.values(FieldType).map((metric: FieldType, index) => {
          return (
            <Fragment key={index}>
              <Checkbox
                label={metric}
                checked={seenMetrics.includes(metric)}
                onChange={() => handleChange(metric)}
              />
            </Fragment>
          )
        })}
      </MetricSelectionPopover>
    </>
  )
}

const MetricSelection = ({ seenMetrics, setSeenMetrics }: IMetricSelectionProps) => {
  return (
    <MetricSelectionContainer>
      <FlexContainer gap="10px" alignItems="center">
        <Popover content={PopoverContent({ seenMetrics, setSeenMetrics })} placement="right">
          <Button icon="drag-handle-vertical" />
        </Popover>
      </FlexContainer>
    </MetricSelectionContainer>
  )
}

export default MetricSelection
