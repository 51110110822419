import { MenuItem } from "@blueprintjs/core"
import { ItemRenderer, MultiSelect } from "@blueprintjs/select"
import { useSectors } from "../pages/Ideas/hooks"
import { Sector } from "../../api/world"
import { ReactNode } from "react"

interface SectorMultiSelectProps {
  selectedSectors: Sector[]
  onSectorsChange: (sectors: Sector[]) => void
  placeholder?: string
  onClear?: () => void
}

const SectorMultiSelect = ({
  selectedSectors,
  onSectorsChange,
  placeholder,
  onClear,
}: SectorMultiSelectProps) => {
  const { sectors } = useSectors()

  const renderSector: ItemRenderer<Sector> = (sector, { handleClick, modifiers }) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={sector.id}
        label={`Level ${sector.level}`}
        onClick={handleClick}
        text={sector.name}
      />
    )
  }

  const searchSector = (query: string, sectors: Sector[]) => {
    return sectors.filter(sector => sector.name.toLowerCase().includes(query.toLowerCase()))
  }

  const renderTag = (sector: Sector) => sector.name

  const handleTagRemove = (_tag: ReactNode, index: number) => {
    const newSelection = selectedSectors.filter((_, i) => i !== index)
    onSectorsChange(newSelection)
  }

  const noResults = (
    <MenuItem active={false} disabled={true} icon="zoom-out" text="No Sectors found" />
  )

  return (
    <>
      {sectors && (
        <MultiSelect
          items={sectors.filter(
            sector => !selectedSectors.some(selected => selected.id === sector.id),
          )}
          tagRenderer={renderTag}
          itemRenderer={renderSector}
          itemListPredicate={searchSector}
          onItemSelect={sector => {
            const isSelected = selectedSectors.some(s => s.id === sector.id)
            const newSelection = isSelected
              ? selectedSectors.filter(s => s.id !== sector.id)
              : [...selectedSectors, sector]
            onSectorsChange(newSelection)
          }}
          selectedItems={selectedSectors}
          noResults={noResults}
          fill={true}
          placeholder={placeholder}
          resetOnSelect={false}
          tagInputProps={{
            tagProps: { minimal: true },
            onRemove: handleTagRemove,
          }}
          onClear={onClear}
        />
      )}
    </>
  )
}

export default SectorMultiSelect
