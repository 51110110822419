import { Button, Card } from "@blueprintjs/core"
import styled from "@emotion/styled"
import { FlexContainer } from "../../../../styled/flexbox.styled"

/**
 * Common styled components for the Config page
 */

export const MainContainer = styled(FlexContainer)({
  height: "fit-content",
  maxHeight: "100%",
  width: "100%",
  overflow: "hidden",
  padding: "10px",
})

export const SearchContainer = styled(FlexContainer)({
  width: "600px",
  marginTop: "10px",
})

export const DetailCard = styled(Card)({
  padding: "20px 40px",
  overflow: "hidden",
  flex: 1,
  display: "flex",
  flexDirection: "column",
})

export const AddTickerButton = styled(Button)({
  width: "100%",
})
