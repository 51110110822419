import { CardList } from "@blueprintjs/core"
import styled from "@emotion/styled"
import { FlexContainer } from "../../../../styled/flexbox.styled"

export const ListingsContainer = styled(FlexContainer)({
  height: "100%",
  marginTop: "20px",
  overflow: "hidden",
})

export const HeaderContainer = styled(FlexContainer)({
  margin: "0 0 5px 10px",
})

export const ListingsCardList = styled(CardList)({
  marginBottom: "10px",
})
