import { Dispatch, SetStateAction } from "react"
import { IUser } from "../../../../api/index"
import { Company, Sector } from "../../../../api/world"
import { FlexContainer } from "../../../../styled/flexbox.styled"
import CompanySuggest from "../../../components/CompanySuggest"
import { OmniSuggestLight } from "../../../components/OmniSuggestLight"
import SectorMultiSelect from "../../../components/SectorMultiSelect"
import UserSuggest from "../../../components/UserSuggest"
import { FieldType } from "../types/business"
import FilterItem from "./FilterItem"
import MetricSelection from "./MetricSelection"
import { NocFiltersContainer, StickyContainer } from "./NocFilters.styled"

interface INocFiltersProps {
  userFilter: IUser | null
  stockFilter: { id: number; bloomberg_code: string } | null
  companyFilter: Company | null
  sectorsFilter: Sector[]
  setUserFilter: Dispatch<SetStateAction<IUser | null>>
  setStockFilter: Dispatch<SetStateAction<{ id: number; bloomberg_code: string } | null>>
  setCompanyFilter: Dispatch<SetStateAction<Company | null>>
  setSectorsFilter: Dispatch<SetStateAction<Sector[]>>
  seenMetrics: FieldType[]
  setSeenMetrics: Dispatch<SetStateAction<Array<FieldType>>>
}

const NocFilters = ({
  userFilter,
  stockFilter,
  companyFilter,
  sectorsFilter,
  setUserFilter,
  setStockFilter,
  setCompanyFilter,
  setSectorsFilter,
  seenMetrics,
  setSeenMetrics,
}: INocFiltersProps) => {
  return (
    <StickyContainer>
      <NocFiltersContainer gap="50px" justifyContent="flex-start" alignItems="center">
        <FlexContainer gap="15px" alignItems="center">
          <MetricSelection seenMetrics={seenMetrics} setSeenMetrics={setSeenMetrics} />
          <FilterItem iconName="dollar" value={stockFilter} onClear={() => setStockFilter(null)}>
            <OmniSuggestLight
              exclude={{ ds: true, dsf: true, rf: true }}
              inputValueRenderer={item => item.bloomberg_code}
              // @ts-ignore
              onItemSelect={item => setStockFilter(item)}
              placeholder="Filter by Stock"
              selectedItem={stockFilter}
            />
          </FilterItem>
          <FilterItem iconName="person" value={userFilter} onClear={() => setUserFilter(null)}>
            <UserSuggest
              selectedUser={userFilter}
              // @ts-ignore
              onUserSelect={user => setUserFilter(user)}
              placeholder="Filter by Analyst"
            />
          </FilterItem>
          <FilterItem
            iconName="office"
            value={companyFilter}
            onClear={() => setCompanyFilter(null)}
          >
            <CompanySuggest
              selectedCompany={companyFilter}
              onCompanySelect={company => setCompanyFilter(company)}
              placeholder="Filter by Company"
            />
          </FilterItem>
          <FilterItem iconName="briefcase" value={sectorsFilter} flexGrow={1}>
            <SectorMultiSelect
              selectedSectors={sectorsFilter}
              onSectorsChange={sectors => setSectorsFilter(sectors)}
              placeholder="Filter by Sector"
              onClear={() => setSectorsFilter([])}
            />
          </FilterItem>
        </FlexContainer>
      </NocFiltersContainer>
    </StickyContainer>
  )
}
export default NocFilters
