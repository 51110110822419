import { AgGridReact } from "ag-grid-react"
import { useCallback, useMemo, useRef } from "react"

import { CellClassParams, ITooltipParams } from "ag-grid-community"
import { RowStyle } from "../../types"
import {
  AgGridFilter,
  ScientCellStyle,
  ScientRowStyle,
  ScientValueFormatter,
  columnsDefValueFormatterMapping,
  getCellStyleStrategy,
  rowStyleMapping,
} from "../agGridMapping"

interface IUseAgGridProps {
  columns: {
    field: string
    filter?: AgGridFilter | boolean
    valueFormatter: ScientValueFormatter
    cellStyle: ScientCellStyle
    cellStyleThreshold?: number
    hide?: boolean
    headerTooltip?: string
    tooltipField?: string
  }[]
  pinnedRowStyles?: {
    rowIndex: number
    rowStyle: string
  }[]
}

/**
 * Custom hook to handle ag grid resizing and columns data from Scient backend
 */
export const useAgGrid = ({ columns, pinnedRowStyles }: IUseAgGridProps) => {
  const gridRef = useRef<AgGridReact | null>(null)

  const sizeColumnsToFit = useCallback(() => {
    gridRef.current?.api.sizeColumnsToFit()
  }, [])

  /**
   * Memoized columns data
   * "Account", "Book" and "BBSymbol" are not included because they are grouped into Path in order to build the treeData.
   */
  const columnsData = useMemo(() => {
    return columns.map(column => {
      return {
        headerName: column.field,
        field: column.field,
        filter: column.filter,
        valueFormatter: columnsDefValueFormatterMapping[column.valueFormatter],
        cellStyle: getCellStyleStrategy(column.cellStyle, column.cellStyleThreshold),
        hide: column.hide,
        headerTooltip: column.headerTooltip,
        tooltipField: column.tooltipField,
        menuTabs: column.filter ? ["filterMenuTab"] : [],
      }
    })
  }, [columns])

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
    }
  }, [])

  const getRowStyle = useCallback(
    (params: CellClassParams) => {
      /**
       * In Risks Exposures tables only the Total line pinned at the botttom is highlighted.
       */
      if (params.node.rowPinned === "bottom") {
        const styleObject = pinnedRowStyles?.find(style => style.rowIndex === params.node.rowIndex)

        if (styleObject) {
          const rowStyleKey = styleObject.rowStyle as ScientRowStyle
          return rowStyleMapping[rowStyleKey] as RowStyle
        }

        // Default styling if no matching rowIndex is found
        return undefined
      }
    },
    [pinnedRowStyles],
  )

  return {
    sizeColumnsToFit,
    columnsData,
    defaultColDef,
    gridRef,
    getRowStyle,
  }
}
