import FactoryIcon from "../../../../icons/FactoryIcon"
import { FlexContainer } from "../../../../styled/flexbox.styled"
import { MediumText } from "../../../../styled/text.styled"
import SectorSelect from "../components/SectorSelect"
import { SectorLevel } from "../types"
import { HeaderContainer, SectorContainer, SectorsContainer } from "./Sectors.styled"

export const Sectors = () => (
  <FlexContainer flexDirection="column" gap="10px">
    <HeaderContainer alignItems="center" gap="25px">
      <FactoryIcon height="22px" width="22px" />
      <MediumText>Sectors</MediumText>
    </HeaderContainer>

    <SectorsContainer>
      {Object.values(SectorLevel)
        .filter(value => typeof value === "number")
        .map((value, index) => {
          return (
            <SectorContainer key={value} alignItems="center" gap="50px">
              <MediumText>L{value}</MediumText>
              <SectorSelect level={value as SectorLevel} name={`sectors.${index}`} />
            </SectorContainer>
          )
        })}
    </SectorsContainer>
  </FlexContainer>
)

export default Sectors
