import { ButtonGroup, H2 } from "@blueprintjs/core"
import styled from "@emotion/styled"

import { FlexContainer } from "../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../styled/scientColors"

export const NameContainer = styled(FlexContainer)({
  marginTop: "20px",
})

export const FieldsContainer = styled(FlexContainer)({
  height: "100%",
  marginTop: "30px",
  overflow: "hidden",
})

type EtfNameProps = {
  isDirty: boolean
}

/**
 * Highlight in orange EtfName if user has modified it.
 */
export const EtfName = styled(H2, {
  shouldForwardProp: prop => prop !== "isDirty",
})<EtfNameProps>(props => ({
  color: props.isDirty ? `${SCIENT_COLORS.orange5} !important` : "",
  margin: "0 0 0 10px",
  width: "565px",
}))

export const ActionButtonGroup = styled(ButtonGroup)({
  width: "300px !important",
})
