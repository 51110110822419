import { EtfUpdatePayload, TradingEtf } from "../Etf/types"
/**
 * This function cleans the updated ETF before sending it to back-end
 */

export const formatEtf = (etf: TradingEtf): EtfUpdatePayload => {
  const formattedData = {
    name: etf.name,
    sectors: [
      {
        id: etf.sectors[0].id,
        level: etf.sectors[0].level,
      },
      {
        id: etf.sectors[1].id,
        level: etf.sectors[1].level,
      },
      {
        id: etf.sectors[2].id,
        level: etf.sectors[2].level,
      },
    ],
  }

  return formattedData
}
