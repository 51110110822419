/** @jsxImportSource @emotion/react */
import { H5, Icon, Intent, Spinner, SpinnerSize } from "@blueprintjs/core"
import { useQueryClient } from "@tanstack/react-query"
import { format, parseISO } from "date-fns"
import { Fragment } from "react"
import InfiniteScroll from "react-infinite-scroll-component"

import { Sector } from "../../../api/world"
import { FlexContainerAndItem } from "../../../styled/flexbox.styled"
import { LargeText } from "../../../styled/text.styled"
import { useNewModelUpdateEvent } from "../../../websocket/hooks/useEvents"
import { Button } from "../../components/index"
import { NoModelUpdatesPlaceholder, SpinnerContainer, dateCss } from "./ModelUpdates.styled"
import ModelUpdatesCard from "./ModelUpdatesCard"
import useInfiniteModelUpdates from "./hooks/useInfiniteModelUpdates"

const ModelUpdates = (props: {
  stockParam?: number
  companyParam?: number
  sectorsParam?: Sector[]
  userParam?: number
  seenMetrics: string[]
}) => {
  const queryClient = useQueryClient()
  /**
   * Infinite hook to GET modelUpdatesCards
   */
  const { modelUpdates, fetchNextPage, hasNextPage, isFetching } = useInfiniteModelUpdates({
    stockParam: props.stockParam,
    userParam: props.userParam,
    companyParam: props.companyParam,
    sectorsParam: props.sectorsParam,
  })

  /**
   * Hook to invalidate and refetch models updates
   * when a new live event "model_updates.new" is coming
   */
  useNewModelUpdateEvent(() => {
    queryClient.invalidateQueries({ queryKey: ["noc", "modelUpdates"] })
  })

  if (isFetching && !modelUpdates) {
    return (
      <NoModelUpdatesPlaceholder
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="25px"
      >
        <Spinner intent={Intent.PRIMARY} size={SpinnerSize.STANDARD} />
        <LargeText>Fetching model updates</LargeText>
      </NoModelUpdatesPlaceholder>
    )
  }

  return (
    <>
      {modelUpdates?.length ? (
        <FlexContainerAndItem flexDirection="column" flexGrow={1}>
          <InfiniteScroll
            dataLength={modelUpdates.length}
            loader={
              <SpinnerContainer justifyContent="center">
                <Spinner intent={Intent.SUCCESS} size={SpinnerSize.SMALL} />
              </SpinnerContainer>
            }
            next={fetchNextPage}
            hasMore={!!hasNextPage}
            scrollableTarget="scrollableContainer"
          >
            {modelUpdates?.map((card, index) => {
              const modelUpdateAt = format(parseISO(card.model_updated_at), "dd-MM-yyyy")
              const prevDate =
                index !== 0
                  ? format(parseISO(modelUpdates[index - 1].model_updated_at), "dd-MM-yyyy")
                  : undefined

              return (
                <Fragment key={card.model_updated_at}>
                  {/* Display update date only when dates between cards is changing */}
                  {modelUpdateAt !== prevDate && <H5 css={dateCss}>{modelUpdateAt}</H5>}
                  <ModelUpdatesCard card={card} seenMetrics={props.seenMetrics} />
                </Fragment>
              )
            })}
            {hasNextPage && !isFetching && (
              <Button fill large icon="add" intent="primary" onClick={fetchNextPage} />
            )}
          </InfiniteScroll>
        </FlexContainerAndItem>
      ) : (
        <NoModelUpdatesPlaceholder
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          gap="25px"
        >
          <Icon icon="zoom-out" size={50} />
          <LargeText>No model updates</LargeText>
        </NoModelUpdatesPlaceholder>
      )}
    </>
  )
}

export default ModelUpdates
