import { Listing } from "../types"
import {
  CustomText,
  FlexListingCardContainer,
  ListingCardContainer,
  ListingCardDivider,
  TickerText,
} from "./ListingCard.styled"

interface IListingCardProps {
  index: number
  listing: Listing
}

const ListingCard = ({ index, listing }: IListingCardProps) => {
  return (
    <ListingCardContainer index={index}>
      <FlexListingCardContainer justifyContent="space-between" alignItems="center" gap="5px">
        <TickerText>{listing.bloomberg_code}</TickerText>
        <ListingCardDivider />
        <CustomText>BB-Unique {listing.bb_unique}</CustomText>
        <ListingCardDivider />
        <CustomText>Exchange {listing.exchange.name}</CustomText>
        <ListingCardDivider />
        <CustomText>Currency {listing.currency.name}</CustomText>
      </FlexListingCardContainer>
    </ListingCardContainer>
  )
}

export default ListingCard
