import { Tab, Tabs } from "@blueprintjs/core"
import { useState } from "react"

import { capitalizeFirstLetter } from "../../../utils"
import { ConfigContainer } from "./Config.styled"
import Etf from "./Etf/Etf"
import Stock from "./Stock/Stock"
import { ConfigDisplayTab } from "./types"

const Config = () => {
  const [selectedTab, setSelectedTab] = useState(ConfigDisplayTab.STOCK)

  return (
    <ConfigContainer flexDirection="column" selectedTab={selectedTab}>
      <Tabs
        id="config-tabs"
        onChange={id => setSelectedTab(id as ConfigDisplayTab)}
        selectedTabId={selectedTab}
        /**
         * renderActiveTabPanelOnly avoids warning:
         *      " AG Grid tried to call sizeColumnsToFit() but the grid is coming back
         *      with zero width, maybe the grid is not visible yet on the screen? "
         */
        renderActiveTabPanelOnly
        large
        animate={false} // prevents the indicator of the selected tab from being misplaced
      >
        <Tab
          id={ConfigDisplayTab.STOCK}
          title={capitalizeFirstLetter(ConfigDisplayTab.STOCK)}
          panel={<Stock />}
        />
        <Tab id={ConfigDisplayTab.ETF} title={ConfigDisplayTab.ETF.toUpperCase()} panel={<Etf />} />
      </Tabs>
    </ConfigContainer>
  )
}

export default Config
