import Metadata from "../components/Metadata"
import { TradingStock } from "./types"

interface IStockMetadataProps {
  selectedStock: TradingStock
}

type MetadataField = {
  headerName: string
  field: keyof TradingStock
  isDate?: boolean
}

const metadataFields: MetadataField[] = [
  { headerName: "Created", field: "created_at", isDate: true },
  { headerName: "Updated", field: "updated_at", isDate: true },
  { headerName: "BB Company", field: "bb_company", isDate: false },
]

/**
 * Shows uneditable stock metadata
 */

const StockMetadata = ({ selectedStock }: IStockMetadataProps) => (
  <Metadata selectedItem={selectedStock} metadataFields={metadataFields} />
)

export default StockMetadata
