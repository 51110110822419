export enum ConfigDisplayTab {
  STOCK = "stock",
  ETF = "etf",
}

export enum SectorLevel {
  L1 = 1,
  L2 = 2,
  L3 = 3,
}

export type Exchange = {
  id: number
  name: string
  mic: string
}

export type Currency = {
  id: number
  name: string
  code: string
}

export type Listing = {
  id: number
  instrument_content_type: number
  instrument_id: number
  exchange: Exchange
  bloomberg_code: string
  currency: Currency
  sedol: string
  bb_unique: string
}

export type TradingSector = {
  id: number | null
  name?: string | null
  level: SectorLevel
}
