import { Button, EditableText, Icon } from "@blueprintjs/core"
import { isEmpty } from "lodash"
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"

import { FlexContainer } from "../../../../styled/flexbox.styled"
import { ConfirmDialog } from "../../../components"
import { createToaster } from "../../../utils/createToaster"
import { ErrorIconWithTooltip } from "../../PortfolioManagement/Trading/components/ErrorIconWithTooltip"
import Sectors from "../components/Sectors"
import useUpdateTradingStockMutation from "../hooks/useUpdateTradingStockMutation"
import { formatStock } from "../utils/formatStock"
import StockFields from "./StockFields"
import { ActionButtonGroup, FieldsContainer, StockName } from "./StockForm.styled"
import StockMetadata from "./StockMetadata"
import { TradingStock } from "./types"

interface IStockFormProps {
  selectedStock: TradingStock
  setSelectedStock: Dispatch<SetStateAction<TradingStock>>
}

type confirmDialiogInitialStateProps = {
  open: boolean
  title: string
  dialogText: string
  handleClick: (() => void) | null
  confirmButtonText: string
}

const confirmDialiogInitialState: confirmDialiogInitialStateProps = {
  open: false,
  title: "",
  dialogText: "",
  handleClick: null,
  confirmButtonText: "",
}

const StockForm = ({ selectedStock, setSelectedStock }: IStockFormProps) => {
  const [confirmDialog, setConfirmDialog] = useState(confirmDialiogInitialState)
  // state variable that changes whenever the form is reset. This will trigger a rerender of the EditableText component.
  const [resetKey, setResetKey] = useState(0)

  const methods = useForm<TradingStock>({
    defaultValues: selectedStock,
  })

  // Use effect to reset form when selectedStock changes
  useEffect(() => {
    if (selectedStock) {
      methods.reset(selectedStock)
    }
  }, [selectedStock, methods])

  //Mutate function
  const updateStockMutation = useUpdateTradingStockMutation({
    onSuccess: stock => {
      setSelectedStock(stock)
      createToaster({
        message: `${selectedStock.name} Stock updated with success`,
        intent: "success",
      })
    },
    onError: () => {
      methods.reset()
      createToaster({
        message: `An error occurred updating the Stock ${selectedStock.name}, please refresh the page`,
        intent: "danger",
      })
    },
  })

  /**
   * Callback to submit Form
   */
  const submitForm = useCallback(
    (stock: TradingStock) => {
      const formattedStock = formatStock(stock)
      updateStockMutation.mutate({ stockId: stock.id, stock: formattedStock })
      setConfirmDialog(confirmDialiogInitialState)
    },
    [updateStockMutation],
  )

  const handleSubmitForm = () => {
    setConfirmDialog({
      open: true,
      title: "Update stock",
      dialogText: "You are going update this stock",
      handleClick: () => methods.handleSubmit(submitForm)(),
      confirmButtonText: "Update",
    })
  }

  /**
   * Callback to reset Form
   */
  const resetForm = useCallback(() => {
    methods.reset()
    setConfirmDialog(confirmDialiogInitialState)
    setResetKey(prev => prev + 1) // Update resetKey to force rerender
  }, [methods])

  const handleResetForm = () => {
    setConfirmDialog({
      open: true,
      title: "Reset stock",
      dialogText: "You are going to cancel your change.",
      handleClick: resetForm,
      confirmButtonText: "Reset",
    })
  }

  return (
    <FormProvider {...methods}>
      <form>
        <FlexContainer alignItems="center" justifyContent="space-between" gap="15px">
          <FlexContainer alignItems="center" justifyContent="flex-end">
            <Icon icon="label" size={28} />
            <Controller
              control={methods.control}
              name="name"
              rules={{
                required: "Name is required",
              }}
              render={({ field: { onChange, value }, fieldState: { isDirty } }) => (
                <>
                  <StockName isDirty={isDirty}>
                    <EditableText
                      key={resetKey} // Use resetKey as key
                      value={value}
                      onChange={onChange}
                      placeholder="Edit Name"
                      multiline={true}
                    />
                  </StockName>
                  {methods.formState.errors.name && (
                    <ErrorIconWithTooltip errorMessage={methods.formState.errors.name.message} />
                  )}
                </>
              )}
            />
          </FlexContainer>
          <StockMetadata selectedStock={selectedStock} />
        </FlexContainer>

        <FieldsContainer flexDirection="column" gap="20px">
          <StockFields control={methods.control} />
          <Sectors />
          {methods.formState.isDirty && (
            <FlexContainer justifyContent="flex-end">
              <ActionButtonGroup large fill>
                <Button text="Reset" icon="reset" onClick={handleResetForm} />
                <Button
                  text="Update"
                  icon="upload"
                  intent="primary"
                  onClick={handleSubmitForm}
                  disabled={!isEmpty(methods.formState.errors)}
                />
              </ActionButtonGroup>
            </FlexContainer>
          )}
        </FieldsContainer>

        <ConfirmDialog
          title={confirmDialog.title}
          dialogText={confirmDialog.dialogText}
          open={confirmDialog.open}
          close={() => setConfirmDialog(confirmDialiogInitialState)}
          handleClick={confirmDialog.handleClick as () => void}
          confirmButtonText={confirmDialog.confirmButtonText}
        />
      </form>
    </FormProvider>
  )
}

export default StockForm
