import { Intent } from "@blueprintjs/core"
import { useState } from "react"

import AddNewTickerDialog from "../components/AddNewTickerDialog"
import {
  AddTickerButton,
  DetailCard,
  MainContainer,
  SearchContainer,
} from "../components/CommonStyled.styled"
import Listings from "../components/Listings"
import TickerSuggest from "../components/TickerSuggest"
import useTradingStocks from "../hooks/useTradingStocks"
import StockForm from "./StockForm"
import { TradingStock } from "./types"

const Stock = () => {
  const [selectedStock, setSelectedStock] = useState<TradingStock | undefined>(undefined)
  const [isOpenDialog, setIsOpenDialog] = useState(false)

  const useStocksWrapper = ({ query }: { query: string }) => {
    const { stocks, isLoading, error } = useTradingStocks({ query })
    return { items: stocks ?? [], isLoading, error }
  }

  return (
    <MainContainer flexDirection="column" alignItems="center" justifyContent="center" gap="30px">
      <SearchContainer flexDirection="column" gap="20px">
        <AddTickerButton onClick={() => setIsOpenDialog(true)} icon="plus" intent={Intent.PRIMARY}>
          Add new ticker(s)
        </AddTickerButton>
        <AddNewTickerDialog
          isOpenDialog={isOpenDialog}
          setIsOpenDialog={setIsOpenDialog}
          title={"Add new ticker(s) related to Stock"}
        />
        <TickerSuggest<TradingStock>
          setSelectedItem={setSelectedStock}
          useItems={useStocksWrapper}
          errorMessage="Unable to search stocks, please try to refresh the page."
        />
      </SearchContainer>

      {selectedStock && (
        <DetailCard>
          <StockForm selectedStock={selectedStock} setSelectedStock={setSelectedStock} />
          <Listings listings={selectedStock.listings} />
        </DetailCard>
      )}
    </MainContainer>
  )
}

export default Stock
