import {
  Button,
  Callout,
  Dialog,
  Icon,
  InputGroup,
  Intent,
  Position,
  Tooltip,
} from "@blueprintjs/core"
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"

import { AxiosError } from "axios"
import { FlexContainer } from "../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { MediumText } from "../../../../styled/text.styled"
import { createToaster } from "../../../utils/createToaster"
import { useRequestNewInstrumentsMutation } from "../hooks/useRequestNewInstrumentsMutation"

interface AddNewTickerDialogProps {
  isOpenDialog: boolean
  setIsOpenDialog: Dispatch<SetStateAction<boolean>>
  title: string
}

const AddNewTickerDialog = ({ isOpenDialog, setIsOpenDialog, title }: AddNewTickerDialogProps) => {
  const [tickers, setTickers] = useState("")
  const [isValid, setIsValid] = useState(true)
  const [isAddButtonClicked, setIsAddButtonClicked] = useState(false)

  const inputRef = useRef<HTMLInputElement | null>(null)

  const tickerRegexOnChange = /^[a-zA-Z0-9]{1,5} [a-zA-Z]{2}(?:\s+[eE][qQ][uU][iI][tT][yY])?$/
  // Function to validate tickers on change
  const validateTickersOnChange = (tickerList: string[]) =>
    tickerList.every(ticker => tickerRegexOnChange.test(ticker.trim()))

  // Set the focus on the input
  useEffect(() => {
    if (isOpenDialog) {
      // Delay the focus to ensure the input is rendered
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus()
        }
      }, 0)
    }
  }, [isOpenDialog])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTickers(event.target.value)
    setIsValid(validateTickersOnChange(event.target.value.split(",")))
  }

  const handleClose = () => {
    setIsOpenDialog(false)
    setIsValid(true)
    setTickers("")
    setIsAddButtonClicked(false)
  }

  const requestNewinstrumentsMutation = useRequestNewInstrumentsMutation({
    onSuccess: () => {
      createToaster({
        message: "Request new ticker(s) with success",
        intent: Intent.SUCCESS,
      })
      setIsOpenDialog(false)
      setTickers("")
      setIsAddButtonClicked(false)
    },

    onError: (error: AxiosError) => {
      //Set type assertion
      const errorData = error.response?.data as {
        error?: { __all__?: string[] }
      }
      // Retrieve custom error from Axios response or generic Axios error.message
      const errorMessage = errorData?.error?.__all__ || errorData?.error || error.message
      const errorType = error.response?.status

      createToaster({
        message:
          errorType === 403
            ? "You have reached the limit of requests per day. Please try again tomorrow."
            : errorMessage
              ? `Error: ${errorMessage}`
              : "An error occurred: Failed to request ticker(s)",
        intent: Intent.DANGER,
      })
    },
  })

  const handleSubmit = () => {
    const normalizedTickers = tickers
      .replace(/\./g, "") // Remove all dots
      .replace(/,\s*$/, "") // Remove comma at the end if not followed by a ticker
      .split(",")
      .map(ticker => {
        // Replace consecutive whitespace with a single whitespace and remove "Equity" suffix if present
        const cleanedTicker = ticker
          .replace(/\s+/g, " ")
          .replace(/\s*equity\s*$/i, "")
          .trim()

        return cleanedTicker.trim().toUpperCase() + " Equity"
      })

    const tickerRegexOnSubmit = /^[a-zA-Z0-9]{1,5} [a-zA-Z]{2} Equity$/
    const valid = normalizedTickers.every(ticker => tickerRegexOnSubmit.test(ticker)) // Validate tickers when Add button is clicked
    setIsValid(valid)
    setIsAddButtonClicked(true)

    if (valid) {
      requestNewinstrumentsMutation.mutate({
        tickers: normalizedTickers,
      })
    }
  }

  // Handle key down event to trigger button click on Enter key
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault()
      handleSubmit()
    }
  }

  // Set isAddButtonClicked to false when user has corrected errors.
  useEffect(() => {
    if (isValid && isAddButtonClicked) {
      setIsAddButtonClicked(false)
    }
  }, [isAddButtonClicked, isValid])

  return (
    <Dialog
      className="bp5-dark"
      title={
        <FlexContainer gap="10px">
          <MediumText fontWeight="bold">{title}</MediumText>
          <Tooltip
            content="Add one or multiple ticker(s) separated by a comma"
            placement={Position.TOP}
          >
            <Icon icon="info-sign" />
          </Tooltip>
        </FlexContainer>
      }
      icon="plus"
      isOpen={isOpenDialog}
      onClose={handleClose}
    >
      <InputGroup
        inputRef={inputRef}
        placeholder="Enter Bloomberg ticker(s)..."
        value={tickers}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        large
      />
      {!isValid && isAddButtonClicked && (
        <Callout intent={Intent.PRIMARY}>
          <MediumText color={SCIENT_COLORS.blue5}>
            Please enter valid Bloomberg tickers (e.g., LR FP, ABBN SW
          </MediumText>
          <MediumText color={SCIENT_COLORS.blue5}>or LR FP Equity, ABBN SW Equity).</MediumText>
          <MediumText color={SCIENT_COLORS.blue5}>
            If many tickers, separate them by a comma (,).
          </MediumText>
        </Callout>
      )}
      <FlexContainer justifyContent="flex-end" gap="20px">
        <Button onClick={handleSubmit} intent={Intent.PRIMARY} fill>
          Add tickers
        </Button>
      </FlexContainer>
    </Dialog>
  )
}

export default AddNewTickerDialog
