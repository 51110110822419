import styled from "@emotion/styled"
import { FlexContainer } from "../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../styled/scientColors"

export const NocFiltersContainer = styled(FlexContainer)({
  padding: 20,
  height: "40px",
  margin: "10px 0 10px 0",
})

export const StickyContainer = styled(FlexContainer)({
  position: "sticky",
  top: 0,
  backgroundColor: SCIENT_COLORS.darkGray2,
})
