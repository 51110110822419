import { Button, MenuItem, Spinner } from "@blueprintjs/core"
import { ItemPredicate, ItemRenderer, Select } from "@blueprintjs/select"
import { useController, UseControllerProps } from "react-hook-form"

import { ToastError } from "../../../components"
import { TradingStock } from "../Stock/types"
import useTradingCurrencies from "../hooks/useTradingCurrencies"
import { Currency } from "../types"

export const CurrencySelect = ({ control, name, disabled }: UseControllerProps<TradingStock>) => {
  const {
    field: { value, onChange },
    fieldState: { isDirty },
  } = useController<TradingStock>({ control, name })

  /**
   * Get the currency list
   */
  const { currencies, isLoading, error } = useTradingCurrencies()

  /**
   * generate Menu to render in the dropdown
   */
  const renderCurrency: ItemRenderer<Currency> = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) return null
    return (
      <MenuItem
        active={modifiers.active}
        key={item.code}
        text={item.name}
        onClick={handleClick}
        roleStructure="listoption"
      />
    )
  }

  /**
   * Filter the currency based on the query
   */
  const filterCurrency: ItemPredicate<Currency> = (query, currency, index, exactMatch) => {
    const normalizedTitle = currency.name.toLowerCase()
    const normalizedQuery = query.toLowerCase()

    if (exactMatch) {
      return normalizedTitle === normalizedQuery
    } else {
      return normalizedTitle.indexOf(normalizedQuery) >= 0
    }
  }

  return (
    <>
      {isLoading ? (
        <Spinner size={15} />
      ) : (
        <Select
          items={currencies?.length ? currencies : []} // we want to keep the selected currency name displayed in the Button, so if no currency list we display emmpty list
          itemRenderer={renderCurrency}
          itemPredicate={filterCurrency}
          onItemSelect={currency => onChange(currency)}
          noResults={<MenuItem disabled={true} text="No results" />}
          resetOnSelect={true}
          resetOnClose={true}
        >
          <Button
            text={value ? (value as Currency).name : "Select a currency"}
            rightIcon="caret-down"
            intent={isDirty ? "warning" : "none"}
            small
            fill
            disabled={disabled || !!error}
            icon={disabled && "disable"}
          />
        </Select>
      )}
      <ToastError
        error={error}
        errorMessage="Unable to get currencies, please try to refresh the page."
      />
    </>
  )
}
