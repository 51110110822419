import styled from "@emotion/styled"
import { SCIENT_COLORS } from "../../../../styled/scientColors"

export const MetadataCard = styled.div({
  backgroundColor: `${SCIENT_COLORS.darkGray4} !important`,
  padding: "10px",
  width: "240px",
})

export const Header = styled.th({
  textAlign: "left",
  paddingRight: "20px",
})
