import { MenuItem } from "@blueprintjs/core"
import { ItemPredicate, ItemRenderer, Suggest } from "@blueprintjs/select"
import { AxiosError } from "axios"
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"

import { FlexContainer } from "../../../../styled/flexbox.styled"
import { ToastError } from "../../../components"
import { useDebounce } from "../../../hooks/useDebounce"
import { ItemsContainer, Title } from "./TickerSuggest.styled"

interface SearchableItem {
  id: number
  name: string
  listings?: Array<{ id: number; bloomberg_code: string }>
}

interface ITickerSuggestProps<T extends SearchableItem> {
  setSelectedItem: Dispatch<SetStateAction<T | undefined>>
  useItems: (params: { query: string }) => {
    items: T[]
    isLoading: boolean
    error: AxiosError | null
  }
  errorMessage?: string
}

/**
 * Suggest component that allows searching and selecting tickers
 */

const TickerSuggest = <T extends SearchableItem>({
  useItems,
  setSelectedItem,
  errorMessage = "Unable to search items, please try to refresh the page.",
}: ITickerSuggestProps<T>) => {
  const [query, setQuery] = useState<string>("")

  const inputRef = useRef<HTMLInputElement | null>(null)
  // Set the focus on the input
  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }, 0)
  }, [])

  // Create a debounced function to update the query state
  const debouncedSetQuery = useDebounce((newQuery: string) => {
    setQuery(newQuery)
  }, 500) // Debounce delay in ms

  const { items, isLoading, error } = useItems({ query })

  /**
   * generate Menu to render in the dropdown
   */
  const renderTickers: ItemRenderer<T> = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null
    }
    return (
      <MenuItem
        active={modifiers.active}
        key={item.id}
        onClick={handleClick}
        text={
          <FlexContainer flexDirection="column">
            <Title>{item.name}</Title>
            {/* Show listings only if queryLength is equal to 2 characters or more */}
            {query.length >= 2 && (
              <ItemsContainer flexDirection="column">
                {item.listings &&
                  item.listings?.length > 0 &&
                  item.listings.map(e => <span key={e.id}>{e.bloomberg_code}</span>)}
              </ItemsContainer>
            )}
          </FlexContainer>
        }
      />
    )
  }

  /**
   * Filter the ticker based on the query
   */
  const filterTickers: ItemPredicate<T> = (query, item, _index, exactMatch) => {
    // Show "No ticker found" if query is less than 2 characters
    if (query.length < 2) {
      return false
    }

    // Check if any of the listings symbol and market match the query
    const matchesListings =
      item.listings?.some(listing => {
        return listing.bloomberg_code.includes(query.toUpperCase())
      }) ?? false

    // Return true if any of the listings symbol and market match the query
    return matchesListings
  }

  return (
    <>
      <Suggest
        items={items || []}
        itemRenderer={renderTickers}
        itemPredicate={filterTickers}
        onItemSelect={item => setSelectedItem(item)}
        noResults={
          <MenuItem
            disabled
            text={
              query.length < 2
                ? "Start typing to search..."
                : isLoading
                  ? "Loading..."
                  : "No tickers found"
            }
          />
        }
        inputValueRenderer={item => ""} // returns empty string because we don't want to display Ticker name after selected
        inputProps={{ placeholder: "Search by ticker...", large: true, inputRef: inputRef }}
        query={query}
        onQueryChange={debouncedSetQuery} // Update query on input change
        resetOnSelect
        resetOnClose
        fill
      />
      {error && <ToastError error={error} errorMessage={errorMessage} />}
    </>
  )
}

export default TickerSuggest
