import { Divider, Icon } from "@blueprintjs/core"

import { FlexContainer } from "../../../../styled/flexbox.styled"
import { MediumText } from "../../../../styled/text.styled"
import { Listing } from "../types"
import ListingCard from "./ListingCard"
import { HeaderContainer, ListingsCardList, ListingsContainer } from "./Listings.styled"

interface IListingsProps {
  listings: Listing[]
}

const Listings = ({ listings }: IListingsProps) => (
  <ListingsContainer flexDirection="column" gap="5px">
    <Divider />

    <HeaderContainer gap="25px" justifyContent="space-between">
      <FlexContainer gap="25px">
        <Icon icon="list" size={22} />
        <MediumText>Listings</MediumText>
      </FlexContainer>
    </HeaderContainer>

    <ListingsCardList compact>
      {listings.map((listing, index) => (
        <ListingCard key={index} index={index} listing={listing} />
      ))}
    </ListingsCardList>
  </ListingsContainer>
)

export default Listings
