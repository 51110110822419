import Metadata from "../components/Metadata"
import { TradingEtf } from "./types"

interface IEtfMetadataProps {
  selectedEtf: TradingEtf
}

type MetadataField = {
  headerName: string
  field: keyof TradingEtf
  isDate?: boolean
}

const metadataFields: MetadataField[] = [
  { headerName: "Created", field: "created_at", isDate: true },
  { headerName: "Updated", field: "updated_at", isDate: true },
]

/**
 * Shows uneditable ETF metadata
 */

const EtfMetadata = ({ selectedEtf }: IEtfMetadataProps) => (
  <Metadata selectedItem={selectedEtf} metadataFields={metadataFields} />
)

export default EtfMetadata
