import { Currency, Listing, TradingSector } from "../types"

export enum Cyclicality {
  CYCLICAL = "cyclical",
  NEUTRAL = "neutral",
  DEFENSIVE = "defensive",
  UNKNOWN = "unknown",
}

export type Country = {
  id: number
  name: string
  code: string
}

export type TradingStock = {
  id: number
  name: string
  bb_company: string
  headquarter_country: Country | null
  reporting_currency: Currency | null
  cyclicality: Cyclicality
  sectors: TradingSector[]
  listings: Listing[]
  created_at: string
  updated_at: string
}

export type StockUpdatePayload = {
  name: string
  headquarter_country_id: number | null
  reporting_currency_id: number | null
  cyclicality: Cyclicality
  sectors: Partial<TradingSector>[]
}
